import React, { useEffect } from "react";
import { useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import axios from "axios";

import "./RegisterForm.css";

import CloseIcon from "@material-ui/icons/Close";

const api = axios.create({
  baseURL: "https://wallet-backend-1-b0tm.onrender.com",
  withCredentials: true,
});

const RegisterForm = ({ handleCloseLocal }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const showSuccess = success ? <p className="success">{success}</p> : null;
  const showError = error ? <p className="error">{error}</p> : null;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(email, name, phone);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      setError("E-mail inválido, por favor digite um e-mail válido.");
      setLoading(false);
      return;
    }

    const fullNameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    if (!fullNameRegex.test(name)) {
      setError("Nome inválido, por favor digite seu nome completo.");
      setLoading(false);
      return;
    }

    if (phone.length < 10) {
      setError("Telefone inválido, por favor digite um telefone válido.");
      setLoading(false);
      return;
    }

    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    try {
      const response = await api.post("/waiting-list", {
        email,
        name: firstName,
        lastName,
        phone,
      });
      console.log(response.data);
      const { data } = response.data;
      setSuccess(
        `Cadastro realizado com sucesso! Você está na posição ${data?.position}º da fila de espera.`
      );
      setTimeout(() => {
        handleCloseLocal();
      }, 3000);
    } catch (error) {
      setError("Erro ao cadastrar, tente novamente.");
      setEmail("");
      console.log(error);
      const message = error?.response?.data?.message;
      if (message) {
        setError(message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-form">
      <h2>Formulário</h2>
      <CloseIcon onClick={handleCloseLocal} className="close-icon" />
      <form onSubmit={handleRegister} className="register-form-content">
        <TextField
          label="E-mail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Nome"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          type="tel"
          label="Telefone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            loading={loading}
            className="register-button"
          >
            {loading ? <CircularProgress size={24} /> : "Cadastrar"}
          </Button>
        </div>
      </form>
      {showSuccess}
      {showError}
    </div>
  );
};

export default RegisterForm;
